




























import { defineComponent, useFetch, computed } from '@nuxtjs/composition-api';
import type { PropType } from '@nuxtjs/composition-api';
import { SfButton } from '@storefront-ui/vue';
import type { NuxtError } from '@nuxt/types';
import { useContent } from '~/modules/amplience/composables/useContent';
import Breadcrumbs from '~/modules/amplience/components/atoms/Breadcrumbs.vue';

export default defineComponent({
  components: {
    SfButton,
    Breadcrumbs,
  },
  props: {
    error: {
      type: Object as PropType<NuxtError>,
      required: true,
    },
  },
  setup(props) {
    const { search, data } = useContent();
    const error = computed<NuxtError>(() => props.error);

    const is404 = computed(() => {
      return error.value?.statusCode === 404;
    });

    useFetch(async () => {
      if (error.value?.statusCode) {
        await search(`error-content_${error.value.statusCode}`, 'url');
      }
    });

    return {
      data,
      is404,
      breadcrumbs: computed(() => {
        return [{
          text: 'Home',
          link: '/',
        }, {
          text: is404.value ? 'Page Not Found' : 'An error occurred',
          link: '',
        }];
      }),
    }
  }
});
