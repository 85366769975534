







import { PropType } from 'vue';
import { defineComponent } from '@nuxtjs/composition-api';
import { extractImage } from '@vsf-enterprise/amplience';
import { ImageInterface } from '~/modules/amplience/types';

export default defineComponent({
  name: 'WarrantyLogos',
  props: {
    logoLeft: {
      type: Object as PropType<ImageInterface>,
    },
    logoRight: {
      type: Object as PropType<ImageInterface>,
    },
  },
  setup() {
    const getImageUrl = (
      logo: ImageInterface | undefined,
      defaultImgName: string,
    ): string => {
      let image = { url: `/bbr/${defaultImgName}.png` };

      if (logo) {
        image = extractImage(logo);
      }

      return image.url || '';
    };

    const getImageAlt = (logo: ImageInterface | undefined): string => {
      let image = { alt: `header-logo` };
      if (logo) {
        image = extractImage(logo);
      }

      return image.alt || '';
    };

    return {
      getImageUrl,
      getImageAlt,
    };
  },
});
