import { Logger } from '@vue-storefront/core';
import { Component } from '~/modules/amplience/types';
import { schemaComponents } from '~/modules/amplience/config';

const getComponentProps = (content: any): Component => {
  const { _meta: { schema, deliveryId } } = content;
  const component = schemaComponents[schema];

  if (!component) {
    Logger.error(
      `A component renderer for this schema ${schema} is not defined. Define it in config.ts:schemaComponents in the root of the amplience module.`
    );
  }

  return { component, ...content, deliveryId };
};

export const componentResolver = (content: any, skeleton?: string[]): Component[] => {
  if (!content && skeleton) {
    return skeleton.map((item) => ({
      _meta: {},
      component: item,
      props: {},
    }));
  }

  if (Array.isArray(content)) {
    return content.map((item) => getComponentProps(item));
  }

  return [getComponentProps(content)];
};
