import { Middleware } from '@nuxt/types';
import { usePageStore } from '~/stores/page';
import { Logger } from '~/helpers/logger';
import { magentoUrlResolver, amplienceUrlResolver } from '~/helpers/urlResolver';

const urlResolverMiddleware: Middleware = async (context: any) => {
  const pageStore = usePageStore();
  const { path } = context.route;

  Logger.debug('middleware/url-resolver', path);

  let results: any = await magentoUrlResolver(context, path);

  if (!results || results.type === 'CMS_PAGE') {
    results = await amplienceUrlResolver(context, path);
  }

  if (!results) context.error({ statusCode: 404 });

  pageStore.$patch((state) => {
    state.routeData = results;
  });
};

export default urlResolverMiddleware;
