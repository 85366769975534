import { Plugin } from '@nuxt/types';

interface FormatNumber {
  $fnum(value: number | string): string;
  $fnum(value: number | string, options?: Intl.NumberFormatOptions): string;
  $fnum(value: number | string, locale?: string, options?: Intl.NumberFormatOptions): string;
}

declare module 'vue/types/vue' {
  interface Vue extends FormatNumber {
  }
}

declare module '@nuxt/types' {
  interface Context extends FormatNumber {
  }
}

const plugin : Plugin = (context, inject) => {
  inject('fnum', (value: number | string, locale?: string, options = {}): string => {
    const currentLocale = (locale || context.i18n?.localeProperties?.iso || '').replace('_', '-');

    return new Intl.NumberFormat(currentLocale, options).format(Number(value));
  });
};

export default plugin;
