export default `
  query wishlist(
    $currentPage: Int = 1, 
    $pageSize: Int = 24, 
    $sort: ProductAttributeSortInput, 
    $attributeCodes: [String!]
    ) {
    customer {
      wishlists {
        id
        items_count
        sharing_code
        items_v2(currentPage: $currentPage, pageSize: $pageSize, sort: $sort) {
          items {
            id
            quantity
            description
            added_at
            product {
              attributes_value(attribute_codes: $attributeCodes) {
                code
                value
                label
                position
                sort_order
                attribute_uid
                uid
              }
              ...on ConfigurableProduct {
                variants {
                  attributes {
                    code
                    label
                    uid
                  }
                  product {
                    __typename
                    uid
                    stock_status
                    name
                    sku
                    attributes_value(attribute_codes: $attributeCodes) {
                      label
                      value
                      code
                      position
                      attribute_uid
                      uid
                    }
                    price_range {
                      maximum_price {
                        final_price {
                          currency
                          value
                        }
                        regular_price {
                          currency
                          value
                        }
                      }
                      minimum_price {
                        final_price {
                          currency
                          value
                        }
                        regular_price {
                          currency
                          value
                        }
                      }
                    }
                  }
                }
              }
              uid
              __typename
              sku
              name
              stock_status
              only_x_left_in_stock
              price_range {
                maximum_price {
                  final_price {
                    currency
                    value
                  }
                  regular_price {
                    currency
                    value
                  }
                }
                minimum_price {
                  final_price {
                    currency
                    value
                  }
                  regular_price {
                    currency
                    value
                  }
                }
              }
              url_key
              url_rewrites {
                url
              }
            }
          }
          page_info {
            current_page
            page_size
            total_pages
          }
        }
      }
    }
  }
`;
