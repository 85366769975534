const clickOutside = {
  bind(el, binding) {
    const closeHandler = binding.value;
    binding.name = 'click-outside';

    el._outsideClickHandler = (event) => {
      if (!el.contains(event.target)) {
        event.stopPropagation();
        closeHandler(event);
      }
    };

    document.addEventListener('mousedown', el._outsideClickHandler);
    document.addEventListener('touchstart', el._outsideClickHandler);
  },
  unbind(el) {
    document.removeEventListener('mousedown', el._outsideClickHandler);
    document.removeEventListener('touchstart', el._outsideClickHandler);

    el._outsideClickHandler = null;
  },
};

export default clickOutside;
