





















































































































import { defineComponent, ref, computed, onMounted, useContext } from '@nuxtjs/composition-api';
import { useCart } from '~/modules/checkout/composables/useCart';
import cartGetters from '~/modules/checkout/getters/cartGetters';
import { ConfigurableCartItem } from '~/modules/GraphQL/types';
import { prepareImgUrl } from '~/bbrTheme/helpers/prepareImgUrl';

export default defineComponent({
  name: 'Cart',
  setup() {
    const {
      cart,
      load: loadCart,
      loading,
    } = useCart();
    const context = useContext();
    const getImgUrl = (product) => {
      return prepareImgUrl(context, product);
    }
    const isVisible = ref<boolean>(false);
    const products = computed(() => cartGetters
      .getItems(cart.value)
      .filter(Boolean)
      .map((item) => ({
        ...item,
        product: {
          ...item.product,
          ...[(item as ConfigurableCartItem).configured_variant ?? {}],
          original_sku: item.product.sku,
        },
      })));
    const discount = computed(() => -cartGetters.getDiscountAmount(cart.value));
    const totals = computed(() => cartGetters.getTotals(cart.value));
    const qty = computed(() => cartGetters.getTotalItems(cart.value));
    const getAttributes = (product: ConfigurableCartItem) => product.configurable_options || [];

    const onMouseOver = () => isVisible.value = true;
    const onMouseLeave = () => isVisible.value = false;

    onMounted(() => {
      if (!cart.value?.id) {
        loadCart();
      }
    });

    return {
      cartGetters,
      discount,
      getAttributes,
      qty,
      onMouseOver,
      onMouseLeave,
      isVisible,
      products,
      totals,
      title: computed(() => qty.value === 1 ? '{0} item' : '{0} items'),
      getImgUrl
    };
  },
});
