declare global {
  interface HTMLElement {
    _resizeObserver?: ResizeObserver;
  }
}

import Vue from 'vue';

Vue.directive('resize', {
  inserted: (el, binding) => {
    const callback = binding.value;
    const observer = new ResizeObserver((entries) => {
      entries.forEach((entry) => {
        callback(entry.contentRect);
      });
    });
    observer.observe(el);

    // Attach the observer to the element
    el._resizeObserver = observer;
  },
  unbind: (el) => {
    if (el._resizeObserver) {
      el._resizeObserver.disconnect();
      el._resizeObserver = null;
    }
  },
});
