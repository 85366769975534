import forEach from 'lodash-es/forEach';
import { reactive } from '@nuxtjs/composition-api';
import { searchGetters } from '@vsf-enterprise/algolia';
import {
  StateInterface,
  UseSearchInterface,
  ParamsInterface,
  FilterInterface,
  RequestParamsInterface,
  SearchParamsInterface,
} from '~/modules/amplience/composables/useSearch/useSearch';

export const useSearch = (context: any): UseSearchInterface => {
  const state = reactive<StateInterface>({
    items: [],
    count: 0,
    currentPage: 0,
    countPages: 0,
    isLoaded: false,
  });

  /**
   * Params - { filters: [{ fieldName: 'someFieldName', conditions: { eq: '', in: [] }}] }
   * @param params
   */
  const preparedParams = (params: ParamsInterface): RequestParamsInterface => {
    const query: string[] = [];
    const { filters, ...rest } = params;

    forEach(filters, (filter: FilterInterface): void => {
      const { conditions, fieldName } = filter;
      forEach(conditions, (value, key: string): void => {
        if (key === 'eq') {
          query.push(`${fieldName}:${value}`);
        }

        if (key === 'in') {
          if (value.length) {
            // @ts-ignore
            query.push(`(${value.map((item): string => `${fieldName}:${item}`).join(' OR ')})`);
          }
        }
      });
    });

    return {
      ...rest,
      filters: query.join(' AND '),
    };
  };

  const multipleQueries = async (args: SearchParamsInterface[]): Promise<void> => {
    return await context.$vsf.$algolia.api.search({
      queries: args.map(({ query, params, indexName, locale, sort }: SearchParamsInterface) => ({
        query: query || '',
        params: params ? preparedParams(params) : {},
        entity: indexName,
        locale: locale || 'default',
        sort: sort || 'desc',
      })),
    });
  };

  const search = async (args: SearchParamsInterface): Promise<void> => {
    const response = await getData(args);
    state.items = response.items;
    state.count = response.count;
    state.currentPage = response.currentPage;
    state.countPages = response.countPages;
    state.isLoaded = true;
  };

  const getData = async (args: SearchParamsInterface): Promise<any> => {
    const { query, params, indexName, locale, sort } = args;
    const response = await context.$vsf.$algolia.api.searchIndex(
      query || '',
      params ? preparedParams(params) : {},
      indexName,
      locale || 'default',
      sort || 'desc',
    );

    return {
      items: searchGetters.getItems(response),
      count: response.nbHits,
      currentPage: response.page,
      countPages: response.nbPages,
    };
  }

  return {
    multipleQueries,
    search,
    getData,
    state,
  };
};
