






import { defineComponent, useFetch } from '@nuxtjs/composition-api';
import { useContent } from '@amplience/composables/useContent';

export default defineComponent({
  name: 'BeforeContent',
  props: {
    identifier: {
      type: String,
      default: '',
    },
  },
  setup(props) {
    const { search, data } = useContent();
    const entityId = props.identifier.substring(1) || 'home';

    useFetch(async () => {
      await search(`before-content_${entityId}`, 'url');
    });

    return {
      data,
    };
  },
});
