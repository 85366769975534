import { Plugin } from '@nuxt/types';
import { Product } from '~/modules/catalog/product/types';
import { types, FamilyType } from '~/bbrTheme/modules/catalog/stores/product';
import { getAttributeValue } from '~/bbrTheme/modules/catalog/getters/productGetters';

interface GetAmplienceImageUrl {
  $getAmplienceImageUrl(product: Product, deskType?: string): string;
}

declare module 'vue/types/vue' {
  interface Vue extends GetAmplienceImageUrl {
  }
}

declare module '@nuxt/types' {
  interface Context extends GetAmplienceImageUrl {
  }
}

// Generate the Amplience image url for the product image in the PLP, PDP pages, etc.
const generateUrl = (baseUrl, sku = 'default', family = FamilyType.Wines, deskType = 'deskPDP') => {
  // Get the fallback image for the family type if wrong family type is passed
  const fallbackImage = types[family] || types[FamilyType.Wines];
  
  let url = baseUrl;

  if (!url) {
    throw new Error('Amplience media set url is not defined');
  }

  if (url.endsWith('/')) {
    url = url.slice(0, -1)
  }

  // Return the url with the sku and family type
  return `${url}/${sku}-ms?img404=${fallbackImage.defaultImg}&$${deskType}$&fmt=auto`;
}

const plugin: Plugin = (context, inject) => {
  const {
    env: {
      VSF_AMPLIENCE_MEDIA_SET_URL
    }
  } = context;

  inject('getAmplienceImageUrl', (product: Product, deskType = 'deskPDP'): string => {
    const mainImage = getAttributeValue(product, 'main_image');
    const family = getAttributeValue(product, 'family_type') as FamilyType;

    if (mainImage) {
      return generateUrl(VSF_AMPLIENCE_MEDIA_SET_URL, mainImage, family, deskType);
    }

    return generateUrl(VSF_AMPLIENCE_MEDIA_SET_URL);
  });
};

export default plugin;
