export default `
  mutation GenerateCustomerTokenAsAdminV2($token: String!) {
    generateCustomerTokenAsAdminV2(
      input: {
        token: $token
      }
    ) {
      customer_token
    }
  }
`;
