






import { PropType } from 'vue';
import { computed, defineComponent, useRoute } from '@nuxtjs/composition-api';
import SfBreadcrumbs from '@storefront-ui/vue/src/components/atoms/SfBreadcrumbs/SfBreadcrumbs.vue';
import {
  BreadcrumbInterface,
  getDynamicBreadcrumbs,
} from '~/modules/amplience/helpers/breadcrumbsResolver';

export default defineComponent({
  name: 'Breadcrumbs',
  components: {
    SfBreadcrumbs,
  },
  props: {
    breadcrumbs: {
      type: Array as PropType<BreadcrumbInterface[]>,
    },
    titles: {
      type: Array,
    },
  },
  setup(props) {
    const route = useRoute();

    const items = computed((): BreadcrumbInterface[] => {
      const breadcrumbs = props.breadcrumbs;
      if (breadcrumbs && breadcrumbs.length) return breadcrumbs;
      return getDynamicBreadcrumbs(route.value?.path, props.titles as any);
    });

    return { items };
  }
});
