





















import { defineComponent, onMounted, useContext, ref } from '@nuxtjs/composition-api';
import clickOutside from '~/modules/amplience/directives/v-click-outside';

/**
 * This is a workaround that will be removed when VFS fully replaces hybris
 */
export default defineComponent({
  name: 'AccountModal',
  directives: {
    clickOutside,
  },
  setup() {
    const context = useContext();
    const isVisible = ref<boolean>(false);
    const isLoggedIn = ref<boolean>(false);
    const customerName = ref<string>('');

    const closeDropdown = () => isVisible.value = false;
    const toggleDropdown = () => isVisible.value = !isVisible.value;

    onMounted(() => {
      const jSessionId = context.$cookies.get('JSESSIONID');
      const storedData = localStorage.getItem(`hybris-to-vsf-${jSessionId}-customer-name`);
      if (storedData) {
        isLoggedIn.value = true;
        customerName.value = storedData;
      }
    });

    return {
      customerName,
      isLoggedIn,
      isVisible,
      closeDropdown,
      toggleDropdown,
    };
  },
});
