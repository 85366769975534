














import { defineComponent, ref, onMounted, useContext } from '@nuxtjs/composition-api';

/**
 * This is a workaround that will be removed when VFS fully replaces hybris
 */
export default defineComponent({
  name: 'Cart',
  setup() {
    const context = useContext();
    const qty = ref<string>('');
    const minicart = ref<string>('');
    const isVisible = ref<boolean>(false);
    const genericImage = require('~/assets/bbr/noimage_generic.jpeg');

    const onMouseOver = async () => {
      if (!minicart.value) {
        const data = await fetch(`/cart/rollover/MiniCart?_=${Date.now()}`);
        const html = await data.text();
        const tempDiv = document.createElement('div');
        tempDiv.innerHTML = html.replace(/onerror="optionalImage\(this\)"/g, '');

        for (let i = 0; i < tempDiv.getElementsByTagName('img').length; i++) {
          tempDiv.getElementsByTagName('img')[i].onerror = function () {
            const element = document.querySelector(`[src="${this.src}"]`) as any;
            if (element) {
              element.src = genericImage;
            }
          };
        }
        minicart.value = tempDiv.innerHTML;
      }
      isVisible.value = true;
    };

    const onMouseLeave = () => isVisible.value = false;

    onMounted(() => {
      const jSessionId = context.$cookies.get('JSESSIONID');
      const storedData = localStorage.getItem(`hybris-to-vsf-${jSessionId}-cart-count`);
      if (storedData) {
        qty.value = storedData;
      }
    });

    return {
      qty,
      onMouseOver,
      onMouseLeave,
      minicart,
      isVisible,
    };
  },
});
