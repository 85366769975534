































































































































import { storeToRefs } from 'pinia';
import { computed, defineComponent, ref, useContext, useFetch, useRoute } from '@nuxtjs/composition-api';
import { useNavigation } from '~/modules/amplience/stores/navigation';

export default defineComponent({
  name: 'Navigation',
  props: {
    className: {
      type: String,
      default: 'navigation',
    },
    deliveryId: {
      type: String,
      default: '',
    },
    isMegaMenu: {
      type: Boolean,
      default: true,
    },
    isMobileMenuVisible: {
      type: Boolean,
      default: false,
    },
  },
  emits: ['handle-nav-toggle'],
  setup(props, { emit }) {
    const route = useRoute();
    const context = useContext();
    const navigation = useNavigation();
    const navigations = ref<any[]>([]);
    const activeItem = ref<string>('');
    const isSwitchable = ref<boolean>(false);
    const { getSortedChildrenById } = storeToRefs(navigation);
    const hasNavigationItems = computed(() => navigations.value.length > 0);

    const handleTopLevelItem = (deliveryId: string) => {
      if (activeItem.value === deliveryId) {
        activeItem.value = '';
      } else {
        activeItem.value = deliveryId;
      }
    };

    const handleNavClose = () => emit('handle-nav-toggle');

    useFetch(async () => {
      await navigation.fetchNodes(context);
      navigations.value = getSortedChildrenById.value(props.deliveryId);
    });

    return {
      activeItem,
      isSwitchable,
      handleTopLevelItem,
      getSortedChildrenById,
      handleNavClose,
      isActive: (link: string): string => (link === route.value.path) ? 'active' : '',
      navigations,
      hasNavigationItems,
    };
  },
});
