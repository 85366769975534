























































import { defineComponent, ref } from '@nuxtjs/composition-api';

/**
 * This is a workaround that will be removed when VFS fully replaces hybris
 */
export default defineComponent({
  name: 'Search',
  setup() {
    const query = ref<string>('');
    const cache = ref<object>({});
    const products = ref<any[]>([]);
    const isFocused = ref<boolean>(false);
    let searchTimeout = null;

    const onChange = async (): Promise<void> => {
      clearTimeout(searchTimeout);
      searchTimeout = setTimeout(async () => {
        if (query.value.length >= 3 && !cache.value[query.value]) {
          const data = await fetch(`/search/autocomplete/SearchBox?term=${query.value}`);
          cache.value[query.value] = await data.json();
        }
        products.value = cache.value[query.value] ? cache.value[query.value]['products'] : [];
      }, 300);
    };

    const getFormattedValue = (product: any): string => product?.price?.formattedValue || '';

    const submit = (element: any): void => {
      if (query.value) {
        element.target.submit();
      }
    };

    return {
      onChange,
      submit,
      cache,
      query,
      products,
      isFocused,
      getFormattedValue,
    };
  },
});
