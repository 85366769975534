// This file enables backwards-compatibility between the old and new maturity labels
// TODO: Remove MaturityOld and replace its usages with Maturity once maturity migration is completed
export enum MaturityOld {
  ForLayingDown = 'For laying down',
  ReadyButWillImprove = 'Ready, but will improve',
  ReadyButWillKeep = 'Ready, but will keep',
  DrinkNow = 'Drink now',
}

// TODO: Remove MATURITY_OLD_ROTATION and its usages in this file once maturity migration is completed
// Accounts for the ReadyButWillImprove icon to be angled at 45deg
const MATURITY_OLD_ROTATION = {
  [MaturityOld.ForLayingDown]: 'rotate-45',
  [MaturityOld.ReadyButWillImprove]: '',
  [MaturityOld.ReadyButWillKeep]: '-rotate-45',
  [MaturityOld.DrinkNow]: '-rotate-45',
};

// TODO: Remove MATURITY_COLOURS_OLD and its usages in this file once maturity migration is completed
const MATURITY_COLOURS_OLD = {
  [MaturityOld.DrinkNow]: {
    class: 'maturity-800',
    rgb: 'rgba(165, 47, 24, 1)',
  },
  [MaturityOld.ReadyButWillKeep]: {
    class: 'maturity-600',
    rgb: 'rgba(189, 61, 31, 1)',
  },
  [MaturityOld.ReadyButWillImprove]: {
    class: 'maturity-400',
    rgb: 'rgba(215, 148, 140, 1)',
  },
  [MaturityOld.ForLayingDown]: {
    class: 'maturity-200',
    rgb: 'rgba(232, 184, 179, 1)',
  },
}

export const ATTRIBUTE_CODE_MATURITY = 'maturity';

// TODO: Replace the labels here with the PIM alphabet codes once ready
export enum Maturity {
  NotReady = 'Not ready',
  ReadyYouthful = 'Ready - youthful',
  ReadyAtBest = 'Ready - at best',
  ReadyMature = 'Ready - mature',
  Declining = 'Declining',
}

export const SORTED_MATURITY = [...Object.values(MaturityOld), ...Object.values(Maturity)];

// CSS class for maturity icon rotation, accounts for the ReadyYouthful icon to be angled at 45deg
export const MATURITY_ICON_ROTATION = {
  ...MATURITY_OLD_ROTATION,
  [Maturity.NotReady]: 'rotate-45',
  [Maturity.ReadyYouthful]: '',
  [Maturity.ReadyAtBest]: '-rotate-45',
  [Maturity.ReadyMature]: '-rotate-45',
  [Maturity.Declining]: '-rotate-45',
};

// Cellar chart colours
export const MATURITY_COLOURS = {
  ...MATURITY_COLOURS_OLD,
  [Maturity.NotReady]: {
    class: 'maturity-200',
    rgb: 'rgba(232, 184, 179, 1)',
  },
  [Maturity.ReadyYouthful]: {
    class: 'maturity-400',
    rgb: 'rgba(215, 148, 140, 1)',
  },
  [Maturity.ReadyAtBest]: {
    class: 'maturity-600',
    rgb: 'rgba(189, 61, 31, 1)',
  },
  [Maturity.ReadyMature]: {
    class: 'maturity-800',
    rgb: 'rgba(165, 47, 24, 1)',
  },
  [Maturity.Declining]: {
    class: 'maturity-1000',
    rgb: 'rgba(141, 32, 18, 1)',
  },
};
