import Vue from 'vue'
import Router from 'vue-router'
import { normalizeURL, decode } from 'ufo'
import { interopDefault } from './utils'
import scrollBehavior from './router.scrollBehavior.js'

const _60f6cf49 = () => interopDefault(import('../integrations/cellar-services/src/pages/bbx/MyBBX.vue' /* webpackChunkName: "" */))
const _55738f1e = () => interopDefault(import('../integrations/cellar-services/src/pages/bbx/Dashboard.vue' /* webpackChunkName: "" */))
const _0253436e = () => interopDefault(import('../integrations/cellar-services/src/pages/bbx/Bids.vue' /* webpackChunkName: "" */))
const _38aa37a2 = () => interopDefault(import('../integrations/cellar-services/src/pages/bbx/Listings.vue' /* webpackChunkName: "" */))
const _ac50eeaa = () => interopDefault(import('../modules/checkout/pages/Cart.vue' /* webpackChunkName: "" */))
const _1582489b = () => interopDefault(import('../integrations/cellar-services/src/pages/MyCellar.vue' /* webpackChunkName: "" */))
const _5f550f3e = () => interopDefault(import('../integrations/cellar-services/src/pages/CellarItems.vue' /* webpackChunkName: "" */))
const _d97a5630 = () => interopDefault(import('../integrations/cellar-services/src/pages/CellarOverview.vue' /* webpackChunkName: "" */))
const _18e513c1 = () => interopDefault(import('../integrations/cellar-services/src/pages/CellarValuations.vue' /* webpackChunkName: "" */))
const _e503b35e = () => interopDefault(import('../modules/checkout/pages/Checkout.vue' /* webpackChunkName: "" */))
const _27a262c6 = () => interopDefault(import('../modules/checkout/pages/Checkout/Billing.vue' /* webpackChunkName: "" */))
const _ea43beb0 = () => interopDefault(import('../modules/checkout/pages/Checkout/Payment.vue' /* webpackChunkName: "" */))
const _4d358908 = () => interopDefault(import('../modules/checkout/pages/Checkout/Shipping.vue' /* webpackChunkName: "" */))
const _7a19f1ba = () => interopDefault(import('../modules/checkout/pages/Checkout/ThankYou.vue' /* webpackChunkName: "" */))
const _90e710f8 = () => interopDefault(import('../modules/checkout/pages/Checkout/UserAccount.vue' /* webpackChunkName: "" */))
const _6feb273d = () => interopDefault(import('../pages/Cms.vue' /* webpackChunkName: "pages/Cms" */))
const _334aaf70 = () => interopDefault(import('../modules/customer/pages/MyAccount/MyAccount.vue' /* webpackChunkName: "" */))
const _bdb2f244 = () => interopDefault(import('../modules/customer/pages/MyAccount/AddressesDetails/AddressesDetails.vue' /* webpackChunkName: "" */))
const _a0b36474 = () => interopDefault(import('../modules/customer/pages/MyAccount/MyNewsletter.vue' /* webpackChunkName: "" */))
const _51c00fda = () => interopDefault(import('../modules/customer/pages/MyAccount/MyProfile/MyProfile.vue' /* webpackChunkName: "" */))
const _51c4725e = () => interopDefault(import('../modules/customer/pages/MyAccount/MyReviews.vue' /* webpackChunkName: "" */))
const _34025bdc = () => interopDefault(import('../modules/customer/pages/MyAccount/MyWishlist.vue' /* webpackChunkName: "" */))
const _614cbb1e = () => interopDefault(import('../modules/customer/pages/MyAccount/OrderHistory/OrderHistory.vue' /* webpackChunkName: "" */))
const _3f0bc8b1 = () => interopDefault(import('../bbrTheme/pages/customer/MyAccount/MyStoreCredit.vue' /* webpackChunkName: "" */))
const _0a594199 = () => interopDefault(import('../modules/customer/pages/MyAccount/ResetPassword.vue' /* webpackChunkName: "" */))
const _e4866bac = () => interopDefault(import('../modules/customer/pages/MyAccount/AddressesDetails/AddressNew.vue' /* webpackChunkName: "" */))
const _39e18bf0 = () => interopDefault(import('../modules/customer/pages/MyAccount/AddressesDetails/AddressEdit.vue' /* webpackChunkName: "" */))
const _27318dd6 = () => interopDefault(import('../modules/customer/pages/MyAccount/OrderHistory/SingleOrder/SingleOrder.vue' /* webpackChunkName: "" */))
const _1f9edd2c = () => interopDefault(import('../bbrTheme/pages/grapes.vue' /* webpackChunkName: "" */))
const _115548ca = () => interopDefault(import('../pages/Home.vue' /* webpackChunkName: "pages/Home" */))
const _a1cb5b5a = () => interopDefault(import('../modules/azure-b2c-auth/pages/auth/login.vue' /* webpackChunkName: "" */))
const _877af60c = () => interopDefault(import('../modules/assisted-shopper/pages/loginAsCustomer.vue' /* webpackChunkName: "" */))
const _478a714b = () => interopDefault(import('../pages/Page.vue' /* webpackChunkName: "pages/Page" */))
const _5439c6af = () => interopDefault(import('../bbrTheme/pages/producers.vue' /* webpackChunkName: "" */))
const _7ae2bbe6 = () => interopDefault(import('../bbrTheme/pages/regions.vue' /* webpackChunkName: "" */))
const _41778e4d = () => interopDefault(import('../bbrTheme/pages/styleguide.vue' /* webpackChunkName: "" */))
const _6175f614 = () => interopDefault(import('../modules/amplience/pages/visualisation.vue' /* webpackChunkName: "" */))
const _1ed0f9fc = () => interopDefault(import('../modules/azure-b2c-auth/pages/auth/create.vue' /* webpackChunkName: "" */))
const _44dac662 = () => interopDefault(import('../modules/azure-b2c-auth/pages/auth/redirect.vue' /* webpackChunkName: "" */))
const _1b9d64cb = () => interopDefault(import('../integrations/cellar-services/src/pages/bbx/SellerRegistration.vue' /* webpackChunkName: "" */))
const _74666628 = () => interopDefault(import('../modules/cellar-plan/pages/signup.vue' /* webpackChunkName: "" */))

const emptyFn = () => {}

Vue.use(Router)

export const routerOptions = {
  mode: 'history',
  base: '/',
  linkActiveClass: 'nuxt-link-active',
  linkExactActiveClass: 'nuxt-link-exact-active',
  scrollBehavior,

  routes: [{
    path: "/bbx",
    component: _60f6cf49,
    redirect: "/bbx/overview",
    name: "bbx___default",
    children: [{
      path: "alerts",
      component: _55738f1e,
      name: "bbx-alerts___default"
    }, {
      path: "bids-placed",
      component: _55738f1e,
      name: "bbx-bids-placed___default"
    }, {
      path: "bids-received",
      component: _0253436e,
      name: "bbx-bids-received___default"
    }, {
      path: "listings",
      component: _38aa37a2,
      name: "bbx-listings___default"
    }, {
      path: "overview",
      component: _55738f1e,
      name: "bbx-overview___default"
    }]
  }, {
    path: "/cart",
    component: _ac50eeaa,
    name: "cart___default"
  }, {
    path: "/cellar",
    component: _1582489b,
    redirect: "/cellar/overview",
    name: "cellar___default",
    children: [{
      path: "items",
      component: _5f550f3e,
      name: "cellar-items___default"
    }, {
      path: "overview",
      component: _d97a5630,
      name: "cellar-overview___default"
    }, {
      path: "valuations",
      component: _18e513c1,
      name: "cellar-valuations___default"
    }]
  }, {
    path: "/checkout",
    component: _e503b35e,
    name: "checkout___default",
    children: [{
      path: "billing",
      component: _27a262c6,
      name: "billing___default"
    }, {
      path: "payment",
      component: _ea43beb0,
      name: "payment___default"
    }, {
      path: "shipping",
      component: _4d358908,
      name: "shipping___default"
    }, {
      path: "thank-you",
      component: _7a19f1ba,
      name: "thank-you___default"
    }, {
      path: "user-account",
      component: _90e710f8,
      name: "user-account___default"
    }]
  }, {
    path: "/Cms",
    component: _6feb273d,
    name: "Cms___default"
  }, {
    path: "/customer",
    component: _334aaf70,
    meta: {"titleLabel":"My Account"},
    name: "customer___default",
    children: [{
      path: "addresses-details",
      component: _bdb2f244,
      meta: {"titleLabel":"Addresses details"},
      name: "customer-addresses-details___default"
    }, {
      path: "my-newsletter",
      component: _a0b36474,
      meta: {"titleLabel":"My newsletter"},
      name: "customer-my-newsletter___default"
    }, {
      path: "my-profile",
      component: _51c00fda,
      meta: {"titleLabel":"My profile"},
      name: "customer-my-profile___default"
    }, {
      path: "my-reviews",
      component: _51c4725e,
      meta: {"titleLabel":"My reviews"},
      name: "customer-my-reviews___default"
    }, {
      path: "my-wishlist",
      component: _34025bdc,
      meta: {"titleLabel":"My wishlist"},
      name: "customer-my-wishlist___default"
    }, {
      path: "order-history",
      component: _614cbb1e,
      meta: {"titleLabel":"Order history"},
      name: "customer-order-history___default"
    }, {
      path: "/my-store-credit",
      component: _3f0bc8b1,
      meta: {"titleLabel":"My Store Credit"},
      alias: "/customer/my-store-credit",
      name: "customer-my-store-credit___default"
    }, {
      path: "/reset-password",
      component: _0a594199,
      alias: "/customer/account/createPassword",
      name: "reset-password___default"
    }, {
      path: "addresses-details/create",
      component: _e4866bac,
      meta: {"titleLabel":"Addresses details"},
      name: "customer-addresses-details-new___default"
    }, {
      path: "addresses-details/edit/:addressId",
      component: _39e18bf0,
      meta: {"titleLabel":"Addresses details"},
      props: true,
      name: "customer-addresses-details-edit___default"
    }, {
      path: "order-history/:orderId",
      component: _27318dd6,
      meta: {"titleLabel":"Order history"},
      props: true,
      name: "customer-single-order___default"
    }]
  }, {
    path: "/grapes",
    component: _1f9edd2c,
    name: "grapes___default"
  }, {
    path: "/Home",
    component: _115548ca,
    name: "Home___default"
  }, {
    path: "/login",
    component: _a1cb5b5a,
    name: "login___default"
  }, {
    path: "/login-as-customer",
    component: _877af60c,
    name: "login-as-customer___default"
  }, {
    path: "/Page",
    component: _478a714b,
    name: "Page___default"
  }, {
    path: "/preview",
    component: _115548ca,
    name: "home___preview"
  }, {
    path: "/producers",
    component: _5439c6af,
    name: "producers___default"
  }, {
    path: "/regions",
    component: _7ae2bbe6,
    name: "regions___default"
  }, {
    path: "/styleguide",
    component: _41778e4d,
    name: "styleguide___default"
  }, {
    path: "/visualization",
    component: _6175f614,
    name: "visualization___default"
  }, {
    path: "/auth/create",
    component: _1ed0f9fc,
    name: "azure-auth-create___default"
  }, {
    path: "/auth/login",
    component: _a1cb5b5a,
    name: "azure-auth-login___default"
  }, {
    path: "/auth/redirect",
    component: _44dac662,
    name: "azure-auth-redirect___default"
  }, {
    path: "/bbx/seller-registration",
    component: _1b9d64cb,
    name: "bbx-seller-registration___default"
  }, {
    path: "/cellar-plan/sign-up",
    component: _74666628,
    name: "cellar-plan-signup___default"
  }, {
    path: "/preview/bbx",
    component: _60f6cf49,
    redirect: "/bbx/overview",
    name: "bbx___preview",
    children: [{
      path: "alerts",
      component: _55738f1e,
      name: "bbx-alerts___preview"
    }, {
      path: "bids-placed",
      component: _55738f1e,
      name: "bbx-bids-placed___preview"
    }, {
      path: "bids-received",
      component: _0253436e,
      name: "bbx-bids-received___preview"
    }, {
      path: "listings",
      component: _38aa37a2,
      name: "bbx-listings___preview"
    }, {
      path: "overview",
      component: _55738f1e,
      name: "bbx-overview___preview"
    }]
  }, {
    path: "/preview/cart",
    component: _ac50eeaa,
    name: "cart___preview"
  }, {
    path: "/preview/cellar",
    component: _1582489b,
    redirect: "/cellar/overview",
    name: "cellar___preview",
    children: [{
      path: "items",
      component: _5f550f3e,
      name: "cellar-items___preview"
    }, {
      path: "overview",
      component: _d97a5630,
      name: "cellar-overview___preview"
    }, {
      path: "valuations",
      component: _18e513c1,
      name: "cellar-valuations___preview"
    }]
  }, {
    path: "/preview/checkout",
    component: _e503b35e,
    name: "checkout___preview",
    children: [{
      path: "billing",
      component: _27a262c6,
      name: "billing___preview"
    }, {
      path: "payment",
      component: _ea43beb0,
      name: "payment___preview"
    }, {
      path: "shipping",
      component: _4d358908,
      name: "shipping___preview"
    }, {
      path: "thank-you",
      component: _7a19f1ba,
      name: "thank-you___preview"
    }, {
      path: "user-account",
      component: _90e710f8,
      name: "user-account___preview"
    }]
  }, {
    path: "/preview/Cms",
    component: _6feb273d,
    name: "Cms___preview"
  }, {
    path: "/preview/customer",
    component: _334aaf70,
    meta: {"titleLabel":"My Account"},
    name: "customer___preview",
    children: [{
      path: "addresses-details",
      component: _bdb2f244,
      meta: {"titleLabel":"Addresses details"},
      name: "customer-addresses-details___preview"
    }, {
      path: "my-newsletter",
      component: _a0b36474,
      meta: {"titleLabel":"My newsletter"},
      name: "customer-my-newsletter___preview"
    }, {
      path: "my-profile",
      component: _51c00fda,
      meta: {"titleLabel":"My profile"},
      name: "customer-my-profile___preview"
    }, {
      path: "my-reviews",
      component: _51c4725e,
      meta: {"titleLabel":"My reviews"},
      name: "customer-my-reviews___preview"
    }, {
      path: "my-wishlist",
      component: _34025bdc,
      meta: {"titleLabel":"My wishlist"},
      name: "customer-my-wishlist___preview"
    }, {
      path: "order-history",
      component: _614cbb1e,
      meta: {"titleLabel":"Order history"},
      name: "customer-order-history___preview"
    }, {
      path: "addresses-details/create",
      component: _e4866bac,
      meta: {"titleLabel":"Addresses details"},
      name: "customer-addresses-details-new___preview"
    }, {
      path: "/preview/my-store-credit",
      component: _3f0bc8b1,
      meta: {"titleLabel":"My Store Credit"},
      alias: "/customer/my-store-credit",
      name: "customer-my-store-credit___preview"
    }, {
      path: "/preview/reset-password",
      component: _0a594199,
      alias: "/customer/account/createPassword",
      name: "reset-password___preview"
    }, {
      path: "addresses-details/edit/:addressId",
      component: _39e18bf0,
      meta: {"titleLabel":"Addresses details"},
      props: true,
      name: "customer-addresses-details-edit___preview"
    }, {
      path: "order-history/:orderId",
      component: _27318dd6,
      meta: {"titleLabel":"Order history"},
      props: true,
      name: "customer-single-order___preview"
    }]
  }, {
    path: "/preview/grapes",
    component: _1f9edd2c,
    name: "grapes___preview"
  }, {
    path: "/preview/Home",
    component: _115548ca,
    name: "Home___preview"
  }, {
    path: "/preview/login",
    component: _a1cb5b5a,
    name: "login___preview"
  }, {
    path: "/preview/login-as-customer",
    component: _877af60c,
    name: "login-as-customer___preview"
  }, {
    path: "/preview/Page",
    component: _478a714b,
    name: "Page___preview"
  }, {
    path: "/preview/producers",
    component: _5439c6af,
    name: "producers___preview"
  }, {
    path: "/preview/regions",
    component: _7ae2bbe6,
    name: "regions___preview"
  }, {
    path: "/preview/styleguide",
    component: _41778e4d,
    name: "styleguide___preview"
  }, {
    path: "/preview/visualization",
    component: _6175f614,
    name: "visualization___preview"
  }, {
    path: "/preview/auth/create",
    component: _1ed0f9fc,
    name: "azure-auth-create___preview"
  }, {
    path: "/preview/auth/login",
    component: _a1cb5b5a,
    name: "azure-auth-login___preview"
  }, {
    path: "/preview/auth/redirect",
    component: _44dac662,
    name: "azure-auth-redirect___preview"
  }, {
    path: "/preview/bbx/seller-registration",
    component: _1b9d64cb,
    name: "bbx-seller-registration___preview"
  }, {
    path: "/preview/cellar-plan/sign-up",
    component: _74666628,
    name: "cellar-plan-signup___preview"
  }, {
    path: "/",
    component: _115548ca,
    name: "home___default"
  }, {
    path: "/preview/:slug+",
    component: _478a714b,
    name: "page___preview"
  }, {
    path: "/:slug+",
    component: _478a714b,
    name: "page___default"
  }],

  fallback: false
}

export function createRouter (ssrContext, config) {
  const base = (config._app && config._app.basePath) || routerOptions.base
  const router = new Router({ ...routerOptions, base  })

  // TODO: remove in Nuxt 3
  const originalPush = router.push
  router.push = function push (location, onComplete = emptyFn, onAbort) {
    return originalPush.call(this, location, onComplete, onAbort)
  }

  const resolve = router.resolve.bind(router)
  router.resolve = (to, current, append) => {
    if (typeof to === 'string') {
      to = normalizeURL(to)
    }
    return resolve(to, current, append)
  }

  return router
}
