// DEPRECATED: This file should not be used anymore.
// It was used to generate the Amplience image url for the product image in the PLP, PDP pages, etc.
// The new approach is to use the plugin getAmplienceImageUrl.ts
// Example of usage: $getAmplienceImageUrl(product)

import { UseContextReturn } from '~/types/core';
import { getAttributeValue } from '~/bbrTheme/modules/catalog/getters/productGetters';
import { types, FamilyType } from '~/bbrTheme/modules/catalog/stores/product';

const generateUrl = (baseUrl, sku = 'default', family = FamilyType.Wines, deskType = 'deskPDP') => {
  // Get the fallback image for the family type if wrong family type is passed
  const fallbackImage = types[family] || types[FamilyType.Wines];
  
  let url = baseUrl;

  if (!url) {
    throw new Error('Amplience media set url is not defined');
  }

  if (url.endsWith('/')) {
    url = url.slice(0, -1)
  }

  return `${url}/${sku}-ms?img404=${fallbackImage.defaultImg}&$${deskType}$&fmt=auto`;
}

export const prepareImgUrl = (context: UseContextReturn, product: any, deskType = 'deskPDP') => {
  const mainImage = getAttributeValue(product, 'main_image');
  const family = getAttributeValue(product, 'family_type') as FamilyType;
  const {
    env: {
      VSF_AMPLIENCE_MEDIA_SET_URL
    }
  } = context;

  if (mainImage) {
    return generateUrl(VSF_AMPLIENCE_MEDIA_SET_URL, mainImage, family, deskType);
  }

  return generateUrl(VSF_AMPLIENCE_MEDIA_SET_URL);
};
