












import { PropType } from 'vue';
import { defineComponent, computed } from '@nuxtjs/composition-api';
import { extractComponents } from '@vsf-enterprise/amplience';
import { componentResolver } from '~/modules/amplience/helpers/componentResolver';

export default defineComponent({
  name: 'RenderContent',
  props: {
    content: {
      type: [Array, Object],
    },
    skeleton: {
      type: Array as PropType<string[]>,
    },
  },
  setup(props) {
    return {
      components: computed(() => (
        extractComponents(
          componentResolver(props.content, props.skeleton)
        )
      )),
    };
  },
});
