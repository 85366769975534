import { defineStore } from 'pinia';
import { useSearch } from '~/modules/amplience/composables/useSearch';
import { NavigationStoreInterface } from '~/modules/amplience/types';

export const useNavigation = defineStore('navigation', {
  state: () => <NavigationStoreInterface>({
    navigations: [],
  }),
  getters: {
    getChildrenById(state: any) {
      return (parentId: string) => state.navigations.filter((navigation) => navigation.parentId === parentId);
    },
    getSortedChildrenById(state: any) {
      return (parentId: string) => state.getChildrenById(parentId).sort((a, b) => a.sortOrder - b.sortOrder);
    }
  },
  actions: {
    async fetchNodes(ctx) {
      if (!this.navigations.length) {
        const { getData } = useSearch(ctx);
        const data = await getData({
          indexName: 'navigations',
          params: {
            hitsPerPage: 1000,
          },
        });
        this.navigations = data?.items ?? [];
      }
    },
  },
});
