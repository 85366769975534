














import { defineComponent, ref, onMounted, useContext } from '@nuxtjs/composition-api';

export default defineComponent({
  name: 'Wishlist',
  setup() {
    const context = useContext();
    const qty = ref<string>('');

    onMounted(() => {
      const jSessionId = context.$cookies.get('JSESSIONID');
      const storedData = localStorage.getItem(`hybris-to-vsf-${jSessionId}-wishlist-count`);
      if (storedData) {
        qty.value = storedData;
      }
    });

    return {
      qty,
    };
  },
});
