
























import {
  defineComponent,
  useRoute,
  computed,
  watch,
} from '@nuxtjs/composition-api';
import AppHeader from '~/bbrTheme/components/AppHeader.vue';
import AppFooter from '~/bbrTheme/components/AppFooter.vue';
import PreviewMessage from '~/modules/amplience/components/molecules/PreviewMessage.vue';
import ScrollToTop from '~/bbrTheme/components/ScrollToTop.vue';
import BeforeContent from '~/bbrTheme/components/BeforeContent.vue';
import AssistedShopperHeader from '~/modules/assisted-shopper/components/AssistedShopperHeader.vue';
import { usePageStore } from '~/stores/page';

const tempTypes = [
  'ARTICLE_VIEW',
  'ARTICLE_CATEGORY',
  'ARTICLE_LANDING',
  'CONTENT_PAGE',
];

export default defineComponent({
  name: 'DefaultLayout',
  components: {
    AssistedShopperHeader,
    BeforeContent,
    PreviewMessage,
    AppHeader,
    AppFooter,
    ScrollToTop,
    NotificationGroup: () => import('~/bbrTheme/components/NotificationGroup.vue'),
  },
  transition: 'fade',
  setup() {
    const route = useRoute();
    const { routeData } = usePageStore();
    const pageType = computed(() => routeData?.type);
    const isCellarLayout = computed(() => route.value.path.startsWith('/cellar'));
    const isTempLayout = computed(() => {
      return tempTypes.includes(pageType.value);
    });

    watch(route, () => {
      if (process.client) {
        document.body.setAttribute('tabindex', '-1');
        document.body.focus();
        document.body.removeAttribute('tabindex');
      }
    });

    return {
      route,
      isTempLayout,
      isCellarLayout,
    };
  }
});
