import { Breadcrumb } from '~/modules/catalog/types';
import { getBreadcrumbs, getPrice } from '~/modules/catalog/product/getters/productGetters';

export interface ProductGetters {
  getProductBreadcrumbs: (product: ProductInterface, category?: CategoryInterface) => Breadcrumb[];
  getProductAttributeByCode: (product: ProductInterface, code: string) => ProductCustomAttributes;
  getGroupedVariants: (product: ConfigurableProduct | null, isDelivery: boolean) => any[];
  getSellByCaseOnly: (product: ProductInterface | null) => boolean;
  getRegionLabel: (product: ProductInterface | null) => string;
  getFamilyType: (product: ProductInterface | null) => string;
  getIsMoreSizes: (product: ConfigurableProduct | null) => number;
  getFilteredAttributes: (attributes: ProductCustomAttributes[], allowedList: string[]) => ProductCustomAttributes[];
  getProductConfigurations: (variant: ConfigurableVariant | null) => { [key: string]: string };
  getFirstConfigurableVariant: (product: ConfigurableProduct | null) => ConfigurableVariant | null;
  getIndividualProduct: (product: ProductWithCommonProductCardProps) => ProductInterface;
}

import type { CategoryInterface, ProductInterface, ProductCustomAttributes } from '~/modules/GraphQL/types';
import { ConfigurableProduct, ConfigurableVariant } from '~/modules/GraphQL/types';
import find from 'lodash-es/find';
import {
  ProductWithCommonProductCardProps
} from '~/modules/catalog/category/components/views/useProductsWithCommonCardProps';
import sortBy from 'lodash-es/sortBy';
import isEmpty from 'lodash-es/isEmpty';
import { FamilyType } from '~/bbrTheme/modules/catalog/stores/product';

export enum ProductGroupComponentNameTypesEnum {
  FOR_DELIVERY = 'ForDeliveryProductGroup',
  IN_BOND = 'InBondProductGroup',
  EVENTS = 'EventProductGroup',
  GIFT_VOUCHERS = 'GiftVoucherProductGroup',
}

function groupTypes() {
  return [
    {
      code: 'for_delivery',
      component: ProductGroupComponentNameTypesEnum.FOR_DELIVERY
    },
    {
      code: 'in_bond',
      component: ProductGroupComponentNameTypesEnum.IN_BOND
    },
    {
      code: 'events',
      component: ProductGroupComponentNameTypesEnum.EVENTS
    },
    {
      code: 'gift_vouchers',
      component: ProductGroupComponentNameTypesEnum.GIFT_VOUCHERS
    },
  ]
}

export const getProductGroupType = (code: string) => {
  return groupTypes().find(group => group.code === code);
}

export const getProductBreadcrumbs = (product: ProductInterface, category?: CategoryInterface): Breadcrumb[] => {
  const breadcrumbs = getBreadcrumbs(product, category);

  if (product) {
    breadcrumbs.unshift({
      text: 'Home',
      link: '/',
    });
  }

  return breadcrumbs;
};

export const getProductAttributeByCode = (
  product: ProductInterface | null, code: string
): ProductCustomAttributes | null => {
  const attribute = product?.attributes_value?.find((attribute: ProductCustomAttributes) => attribute.code === code);

  if (attribute) {
    return attribute;
  }

  return null;
};

export const isGiftCard = (product: ProductInterface | null, code = 'is_a_gift_card'): boolean => {
  const attribute = getProductAttributeByCode(product, code);

  return !!attribute?.value;
};

export const getAttributeValue = (product: ProductInterface | null, code: string): string => {
  const attribute = getProductAttributeByCode(product, code);

  if (attribute && String(attribute.value || '').trim()) {
    return String(attribute.value || '').trim();
  }

  return '';
};

export const getAttributeOptionCode = (product: ProductInterface | null, code: string): string => {
  const attribute = getProductAttributeByCode(product, code);
  // @TODO when backend part will be implemented this code should be uncommented
  // if (attribute && String(attribute.option_code || '').trim()) {
  //   return String(attribute.option_code || '').trim();
  // }

  // @TODO when backend part will be implemented this code should be removed
  if (attribute && String(attribute.value || '').trim()) {
    return String(attribute.value || '').trim();
  }

  return '';
};

export const getGroupedOption = (options: any[], uid: string): any => {
  const option = options.find((option: any) => option.uid === uid);

  if (option) {
    return option;
  }

  return null;
};

export const getSortedVariants = (product: ConfigurableProduct | null): any => {
  const variants = product?.variants ?? [];
  variants.sort((a, b) => {
    const mlA = getProductAttributeByCode(a.product, 'dutiable_volume_ml');
    const mlB = getProductAttributeByCode(b.product, 'dutiable_volume_ml');

    if (!mlA && !mlB) return 0;
    if (!mlA) return 1;
    if (!mlB) return -1;
    if (Number(mlA.value) === 750 && Number(mlB.value) !== 750) return -1;
    if (Number(mlB.value) === 750 && Number(mlA.value) !== 750) return 1;

    return Number(mlA.value) - Number(mlB.value);
  });

  return variants;
};

export const getGroupedVariants = (product: ConfigurableProduct | null, isDelivery: boolean): any => {
  const groups = {};

  for (const variant of getSortedVariants(product)) {
    const { value: bottleVolume } = getProductAttributeByCode(variant.product, 'bottle_volume') || {};
    const { value: bottleOrderUnit } = getProductAttributeByCode(variant.product, 'bottle_order_unit') || {};
    const { value: caseOrderUnit } = getProductAttributeByCode(variant.product, 'case_order_unit') || {};

    if (!bottleVolume || !bottleOrderUnit || !caseOrderUnit) {
      continue;
    }

    const price = getPrice(variant.product);

    if (!groups[bottleVolume]) {
      groups[bottleVolume] = {
        name: `${bottleVolume} ${bottleOrderUnit}`,
        code: bottleVolume,
        options: [{
          name: `${caseOrderUnit} x ${bottleVolume}`,
          price: price.final,
          uid: variant.product.sku,
          sku: variant.product.sku,
          product: variant.product,
        }],
      };

      // The bottle option can only be added if the family type is Spirits or Wines and the buying_option is forDelivery
      // Overall this function is a temporary solution and will be rewritten in DG-3269
      if (
        isDelivery &&
        variant.product.__typename === FamilyType.Wines ||
        variant.product.__typename === FamilyType.Spirits
      ) {
        groups[bottleVolume].options.unshift({
          name: `Bottle - 1 x ${bottleVolume}`,
          price: price.final / 6, // @TODO Prices should be discussed
          uid: `default-${variant.product.sku}`,
          sku: variant.product.sku,
          product: variant.product,
        });
      }
    } else {
      groups[bottleVolume]['options'].push({
        name: `${caseOrderUnit} x ${bottleVolume}`,
        price: price.final,
        uid: variant.product.sku,
        sku: variant.product.sku,
        product: variant.product,
      });
    }
  }

  return Object.values(groups);
};

const findCheapest = (variants: ConfigurableVariant[]): ConfigurableVariant | null => {
  const prices = [];
  variants.map((variant: ConfigurableVariant, idx: number) => {
    if (variant?.product?.price_range) {
      const variantPrices = getPrice(variant.product);
      prices.push({
        idx,
        price: variantPrices.regular <= variantPrices.final ? variantPrices.regular : variantPrices.special,
      });
    }
  });
  const sortedPrices = sortBy(prices, (item) => item.price);

  return sortedPrices.length ? variants[sortedPrices[0]?.idx] : null;
}

export const getConfigurableVariant = (
  product: ProductWithCommonProductCardProps,
  selectedFilters
): ProductWithCommonProductCardProps | ConfigurableVariant => {
  const variants = product.variants || [];
  const filteredVariants = variants.filter((variant: ConfigurableVariant) => {
    if (!isEmpty(selectedFilters)) {
      return find(selectedFilters, (filterOptions) => {
        return filterOptions.find(option => {
          return variant.product.attributes_value.find(attribute => {
            return option.id === attribute.code && option.label === attribute.value
          });
        });
      });
    }

    return Number(getAttributeValue(variant.product, 'dutiable_volume_ml')) === 750;
  })
  const cheapestVariant = filteredVariants.length ? findCheapest(filteredVariants) : findCheapest(variants);

  return cheapestVariant || product;
}

// Get product configurations for configurable products to be used in the product add to cart method
export const getProductConfigurations = (variant: ConfigurableVariant | null): { [key: string]: string } => {
  const productConfigurations = {};

  if (variant) {
    variant.attributes.forEach(attribute => {
      productConfigurations[attribute.uid] = attribute.uid;
    });
  }

  return productConfigurations;
}

/**
 * We always can receive maximum one variant and that's the reason why we get the first index
 * @param product
 */
export const getFirstConfigurableVariant = (product: ProductWithCommonProductCardProps): ConfigurableVariant | null => {
  if (product.__typename !== 'ConfigurableProduct') {
    return null;
  }

  return product?.variants[0] || null;
}

export const getIndividualProduct = (product: ProductWithCommonProductCardProps): ProductInterface => {
  const preparedProduct = getFirstConfigurableVariant(product);

  return preparedProduct?.product || product;
};

export const getSellByCaseOnly = (product: ProductInterface | null): boolean => {
  if (!product || !product.sell_by_case_only) {
    return false;
  }
  
  return Boolean(product.sell_by_case_only);
};

export const getRegionLabel = (product: ProductInterface | null): string => {
  return getAttributeValue(product, 'region');
};

export const getFamilyType = (product: ProductInterface | null): string => {
  return getAttributeValue(product, 'family_type');
}

export const getIsMoreSizes = (product: ConfigurableProduct | null): number => {
  const uniqueSizes = [];
  product?.variants?.forEach(variant => {
    variant.attributes.forEach(attribute => {
      if (attribute.code === 'bottle_volume' && !uniqueSizes.includes(attribute.label)) {
        uniqueSizes.push(attribute.label);
      }
    })
  });

  return uniqueSizes.length;
}

export const getFilteredAttributes = (
  attributes: ProductCustomAttributes[],
  allowedList: string[],
): ProductCustomAttributes[] => {
  return attributes.filter(
    (attribute: ProductCustomAttributes) => allowedList.includes(attribute.code) && !!attribute.value
  ).sort(
    (a: ProductCustomAttributes, b: ProductCustomAttributes) => {
      return allowedList.indexOf(a.code) - allowedList.indexOf(b.code);
    }
  );
}

const productGetters: ProductGetters = {
  getProductBreadcrumbs,
  getProductAttributeByCode,
  getGroupedVariants,
  getSellByCaseOnly,
  getRegionLabel,
  getFamilyType,
  getIsMoreSizes,
  getFilteredAttributes,
  getProductConfigurations,
  getFirstConfigurableVariant,
  getIndividualProduct
};

export default productGetters;
