




import { defineComponent, useFetch } from '@nuxtjs/composition-api';
import { useContent } from '~/modules/amplience/composables/useContent';

export default defineComponent({
  name: 'AppFooter',
  setup() {
    const { search, data } = useContent();

    useFetch(async () => {
      await search('footer', 'url');
    });

    return {
      data,
    };
  },
});
