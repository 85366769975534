export const customerCellarQuery = `
  query CPRItems($currentPage: Int = 1, $pageSize: Int = 24, $filter: ProductAttributeFilterInput) {
    customer {
      cpr_items(
        accountData: "default",
        currentPage: $currentPage,
        pageSize: $pageSize,
        filter: $filter
      ) {
        is_default
        account_id
        items {
          uid
          item_id
          url_key
          name
          sku
          parent_sku
          listing_qty
          cases_qty
          bottles_qty
          change_value
          change_value_percentage
          highest_bid
          is_bbx_allowed
          listing_id
          listing_price
          listing_qty
          market_price
          purchase_price
          description {
            html
          }
          url_key
          attributes_value {
            code
            value
            label
            position
            sort_order
          }
          reviews {
            items {
              nickname
              score
            }
          }
        }
        aggregations(filter: {}) {
          attribute_code
          count
          label
          options {
            count
            label
            value
          }
          position
        }
        page_info {
          current_page
          page_size
          total_pages
        }
        sort_fields {
          default
          options {
            label
            value
          }
        }
        total_count
      }
    }
  }
`;
