import { defineStore } from 'pinia';

interface PageState {
  routeData: any;
}

export const usePageStore = defineStore('page', {
  state: (): PageState => ({
    routeData: null,
  }),
  actions: {
    update(data: any) {
      this.routeData = data;
    }
  },
});
