















import { defineComponent, ref, onMounted } from '@nuxtjs/composition-api';
import { SfNotification } from '@storefront-ui/vue';
import { useContext } from '@nuxtjs/composition-api';

export default defineComponent({
  name: 'PreviewMessage',
  components: {
    SfNotification,
  },
  setup() {
    const { app } = useContext();
    const isShow = ref(null);

    onMounted(async () => {
      const vse = await app.$cookies.get('bbr-vse');
      isShow.value = !!vse;
    });

    const stopPreview = async (): Promise<void> => {
      await app.$cookies.remove('bbr-vse');
      window.location.href = '/preview';
    };

    return {
      stopPreview,
      isShow,
    }
  }
});
