import { defineStore } from 'pinia';
import { computed, ref } from '@nuxtjs/composition-api';
import type { SummaryInterface, ItemsInterface } from '@cellar-services/types';

export const useCellarStore = defineStore('cellar', () => {
  // State
  const accountId = ref(null);
  const stats = ref<SummaryInterface>(null);
  const items = ref<ItemsInterface>({});
  const aggregations = ref({});
  const isLoading = ref(false); // both asynchronous & synchronous loading
  const isBlockingLoading = ref(false); // synchronous loading
  const hasLoaded = ref(false);
  const pageSize = ref(24);
  const totalItems = ref(0);
  const totalPages = ref(0);
  const currentPage = ref(1);
  const filters = ref([]);

  // Getters
  const isEmpty = computed(() => Object.keys(items.value).length === 0);
  const areAggregationsEmpty = computed(() => Object.keys(aggregations.value).length === 0);

  const itemsRange = computed(() => {
    let end = currentPage.value * pageSize.value;
    const start = end - pageSize.value + 1;

    if (end > totalItems.value) end = totalItems.value;

    return { start, end };
  });

  const queryParams = computed(() => {
    const object = { currentPage: currentPage.value, pageSize: pageSize.value };

    for (const filter of filters.value) {
      object[filter.code] = filter.value;
    }

    return object;
  });

  const graphQueryParams = computed(() => {
    const object = { currentPage: currentPage.value, pageSize: pageSize.value };

    if (filters.value.length) {
      object['filter'] = {};
    }

    for (const filter of filters.value) {
      object['filter'][filter.code] = { in: [filter.value] };
    }

    return object;
  });

  // Actions
  const setStats = (value) => (stats.value = value);
  const setItems = (value: ItemsInterface) => (items.value = value);
  const setAggregations = (value) => (aggregations.value = value);
  const setIsBlockingLoading = (value) => (isBlockingLoading.value = value);

  const setIsLoading = (value: boolean) => {
    isLoading.value = value;

    if (value === false) {
      hasLoaded.value = true;
    }
  };

  const setTotalPages = (value: number) => (totalPages.value = value);
  const setTotalItems = (value: number) => (totalItems.value = value);

  const setCurrentPage = (context, page: number) => {
    currentPage.value = page;
  };

  const setPageSize = (context, size: number) => {
    pageSize.value = size;
  };

  const clearFilters = () => {
    filters.value = [];
  };

  const setFilter = async (filter) => {
    filters.value = [filter];
  };

  const setAccountId = (value) => (accountId.value = value);

  return {
    stats,
    items,
    aggregations,
    isLoading,
    isBlockingLoading,
    hasLoaded,
    filters,
    isEmpty,
    areAggregationsEmpty,
    queryParams,
    graphQueryParams,
    setStats,
    setItems,
    setAggregations,
    setIsBlockingLoading,
    setIsLoading,
    pageSize,
    totalItems,
    totalPages,
    currentPage,
    itemsRange,
    setCurrentPage,
    setPageSize,
    setTotalItems,
    setTotalPages,
    clearFilters,
    setFilter,
    accountId,
    setAccountId,
  };
});
