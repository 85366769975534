

















import {
  defineComponent,
  onMounted,
  ref,
  useContext,
  useRoute,
  useRouter,
  watch,
} from '@nuxtjs/composition-api';
import jwt from 'jsonwebtoken';
import { useAuth } from '~/modules/assisted-shopper/composables/useAuth';
import { useCustomerStore } from '~/modules/customer/stores/customer';
import { storeToRefs } from 'pinia';

export default defineComponent({
  name: 'AssistedShopperHeader',
  setup() {
    const { app } = useContext();
    const route = useRoute();
    const router = useRouter();
    const isAdmin = ref<boolean>(false);
    const { logout } = useAuth();
    const store = useCustomerStore();
    const { user } = storeToRefs(store);

    const setIsAdmin = () => {
      const token = jwt.decode(
        app.context.$cookies.get('vsf-customer') || ''
      );
      isAdmin.value = !!token?.['admin-id'];
    };

    const logoutAdmin = async () => {
      await logout();
      isAdmin.value = false;
      router.push(app.localeRoute({ name: 'home' }));
    };

    watch(route, setIsAdmin);
    onMounted(setIsAdmin);

    return {
      isAdmin,
      logoutAdmin,
      user,
    };
  }
});
