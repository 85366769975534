/**
 * GraphQL Query that fetches products using received search term and the params
 * for filter, sort and pagination.
 */
export default `
  query getFacetData(
    $search: String = "",
    $filter: ProductAttributeFilterInput,
    $pageSize: Int = 24,
    $currentPage: Int = 1,
    $sort: ProductAttributeSortInput,
    $filteredVariants: Boolean = false,
    $buyingOption: BuyingOptionEnum = DEFAULT,
    $attributeCodes: [String!],
  ) {
    products(
      search: $search,
      filter: $filter,
      pageSize: $pageSize,
      currentPage: $currentPage,
      sort: $sort,
      buying_option: $buyingOption
    ) {
      items {
        __typename
        uid
        sku
        name
        stock_status
        only_x_left_in_stock
        url_key
        attributes_value(attribute_codes: [
          "main_image",
          "family_type",
          "style_temp"
        ]) {
          code
          label
          value
        }
        url_rewrites {
          url
        }
        price_range {
          maximum_price {
            final_price {
              currency
              value
            }
            regular_price {
              currency
              value
            }
          }
          minimum_price {
            final_price {
              currency
              value
            }
            regular_price {
              currency
              value
            }
          }
        }
        rating_summary
        review_count
        reviews {
          items {
            nickname
            score
            average_rating
            ratings_breakdown {
              name
              value
            }
          }
        }
        ... on ConfigurableProduct {
          configurable_options {
            attribute_code
            attribute_uid
          }
          is_more_variant_available
          variants(filtered_variants: $filteredVariants) {
            __typename
            product {
              stock_status
              only_x_left_in_stock
              name
              sku
              sell_by_case_only
              price_range {
                maximum_price {
                  final_price {
                    currency
                    value
                  }
                  regular_price {
                    currency
                    value
                  }
                }
                minimum_price {
                  final_price {
                    currency
                    value
                  }
                  regular_price {
                    currency
                    value
                  }
                }
              }
              attributes_value(attribute_codes: $attributeCodes) {
                attribute_uid
                code
                label
                position
                sort_order
                uid
                value
              }
            }
            attributes {
              code
              label
              uid
            }
          }
        }
        ... on GroupedProduct {
          items {
            product {
              sku
            }
          }
        }
      }
      page_info {
        current_page
        page_size
        total_pages
      }
      total_count
    }
  }
`;
