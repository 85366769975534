

























import { storeToRefs } from 'pinia';
import { defineComponent, useContext, useRouter, ref, computed } from '@nuxtjs/composition-api';
import { useAuth } from '~/modules/azure-b2c-auth/composables/useAuth';
import { useCustomerStore } from '~/modules/customer/stores/customer';
import clickOutside from '~/modules/azure-b2c-auth/directives/v-click-outside';

export default defineComponent({
  name: 'AzureAuth',
  directives: {
    clickOutside,
  },
  setup() {
    const router = useRouter();
    const { app } = useContext();
    const { create, login, logout } = useAuth();
    const { isLoggedIn, user } = storeToRefs(useCustomerStore());
    const isVisible = ref<boolean>(false);

    const closeDropdown = () => isVisible.value = false;
    const toggleDropdown = () => isVisible.value = !isVisible.value;

    const signIn = async (): Promise<void> => {
      await login();
    };

    const signUp = async (): Promise<void> => {
      await create();
    };

    const handleLogout = async (): Promise<void> => {
      closeDropdown();
      await logout();
    };

    const goToAccount = async (): Promise<void> => {
      closeDropdown();
      await router.push(app.localeRoute({ name: 'customer-my-profile' }));
    };

    return {
      signIn,
      signUp,
      isLoggedIn,
      isVisible,
      closeDropdown,
      toggleDropdown,
      goToAccount,
      handleLogout,
      title: computed(() => user.value?.firstname || 'Sign in'),
    };
  },
});
