import { Logger } from '~/helpers/logger';
import { UseContextReturn } from '~/types/core';
import { getPageType } from '~/modules/amplience/helpers/getPageType';

const clearUrl = (context: UseContextReturn, path: string) => (
  path.replace(/[a-z]+\/[cp|]\//gi, '').replace(`/${context.i18n.locale}`, '')
);

export const magentoUrlResolver = async (context: UseContextReturn, path: string) => {
  try {
    const { data, errors } = await context.app.$vsf.$magento.api.route(
      clearUrl(context, path)
    );

    Logger.debug('middleware/url-resolver/magento-result', { data, errors });

    return data?.route ?? null;
  } catch (e) {
    Logger.info('middleware/url-resolver/magento-error', path, e);
  }

  return null;
};

export const amplienceUrlResolver = async (context: UseContextReturn, path: string) => {
  try {
    const url = clearUrl(context, path).replace(/^\/(.*)$/, '$1');

    const [content] = await context.app.$vsf.$ampl.api.getContent({
      url,
    });

    Logger.debug('middleware/url-resolver/amplience-result', { content });

    const schema = content?._meta?.schema || '';
    const pageType = getPageType(schema);

    if (!pageType) {
      // eslint-disable-next-line max-len
      Logger.error(`A page renderer for this schema ${schema} is not defined. Define it in config.ts:schemaPages in the root of the amplience module.`);
    }

    if (content && pageType && !content.isDraft) {
      return {
        type: pageType,
        identifier: url,
      };
    }
  } catch (e) {
    Logger.error('middleware/url-resolver/amplience-error', path, e);
  }

  return null;
};
