















import { defineComponent, ref } from '@nuxtjs/composition-api';
import clickOutside from '~/modules/amplience/directives/v-click-outside';

/**
 * This is a workaround that will be removed when VFS fully replaces hybris
 */
export default defineComponent({
  name: 'CountrySelector',
  directives: {
    clickOutside,
  },
  setup() {
    const isVisible = ref<boolean>(false);
    const closeDropdown = () => isVisible.value = false;
    const toggleDropdown = () => isVisible.value = !isVisible.value;
    return {
      isVisible,
      closeDropdown,
      toggleDropdown,
    };
  },
});
