import compact from 'lodash-es/compact';
import capitalize from 'lodash-es/capitalize';

export interface BreadcrumbInterface {
  text: string;
  link: string;
}

export const getDynamicBreadcrumbs = (path?: string, titles?: string[]): BreadcrumbInterface[] => {
  const routes: string[] = compact((path || '').split('/'));
  const isPreview = routes[0] === 'preview';
  const isVisualization = routes[0] === 'visualization';
  const breadcrumbs: BreadcrumbInterface[] = [{ text: 'Home', link: '/', }];

  switch (routes.length) {
    case 1:
      breadcrumbs.push({
        text: capitalize(routes[0]),
        link: `/${routes[0]}`,
      });
      break;
    case 2:
      breadcrumbs.push({
        text: isVisualization ? titles[0] : capitalize(routes[0]),
        link: `/${routes[0]}`,
      }, {
        text: isVisualization ? titles[1] : titles[0],
        link: `/${routes[0]}/${routes[1]}`,
      });
      break;
    case 3:
      breadcrumbs.push({
        text: capitalize(routes[0]),
        link: `/${routes[0]}`,
      }, {
        text: titles[0],
        link: `/${routes[0]}/${routes[1]}`,
      }, {
        text: titles[1],
        link: path,
      });
      break;
  }

  return breadcrumbs;
};
