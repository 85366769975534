import { ref, useContext, useRouter, onMounted } from '@nuxtjs/composition-api';
import { useApi, useUiNotification } from '~/composables';
import { UseAuthInterface } from './useAuth';
import { AuthenticationResult } from '@azure/msal-common';
import { GenerateCustomerTokenQuery } from '~/modules/azure-b2c-auth/types';
import { useCustomerStore } from '~/modules/customer/stores/customer';
import { useCart } from '~/modules/checkout/composables/useCart';
import { useUser } from '~/modules/customer/composables/useUser';
import { useCellar } from '@cellar-services/composables/useCellar';
import GENERATE_AZURE_TOKEN from '~/modules/azure-b2c-auth/queries/generateCustomerToken.gql';

export const useAuth = (): UseAuthInterface => {
  const accountId = ref<any>(null);
  const { mutate } = useApi();
  const { $msal, $msalConfig, app, localeRoute } = useContext();
  const { logout: logoutCustomer, load: loadCustomer } = useUser();
  const { refreshCellar } = useCellar();
  const { clear, setCart } = useCart();
  const { send: sendNotification } = useUiNotification();
  const customerStore = useCustomerStore();
  const router = useRouter();
  const error = ref<any>(null);

  const handleRedirect = async (): Promise<AuthenticationResult | null> => {
    let result = null;

    try {
      result = await $msal.handleRedirectPromise();
    } catch (error) {
      error.value = error;
    }

    return result;
  };

  const create = async (): Promise<void> => {
    try {
      await $msal.loginRedirect({
        authority: $msalConfig.signUpPolicy,
        scopes: $msalConfig.scopes,
      });
    } catch (error) {
      error.value = error;
    }
  };

  const login = async (): Promise<void> => {
    try {
      await $msal.loginRedirect({
        authority: $msalConfig.signInPolicy,
        scopes: $msalConfig.scopes,
      });
    } catch (error) {
      error.value = error;
    }
  };

  const logout = async (): Promise<void> => {
    try {
      await logoutCustomer({});
      await clear({});
      await $msal.logoutRedirect({
        account: await $msal.getAccountByHomeId(accountId.value),
        authority: $msalConfig.signInPolicy,
        postLogoutRedirectUri: $msalConfig.logoutRedirectUri,
      });
      await router.push(localeRoute({ name: 'home' }));
    } catch (error) {
      error.value = error;
    }
  };

  const authCustomer = async (token: string): Promise<void> => {
    const apiState = app.context.$vsf.$magento.config.state;

    customerStore.setIsLoggedIn(true);
    apiState.setCustomerToken(token);
  };

  const mergeCarts = async (): Promise<void> => {
    const apiState = app.context.$vsf.$magento.config.state;
    const currentCartId = apiState.getCartId();
    const cart = await app.context.$vsf.$magento.api.customerCart();
    const newCartId = cart.data.customerCart.id;

    if (newCartId && currentCartId && currentCartId !== newCartId) {
      const { data } = await app.context.$vsf.$magento.api.mergeCarts(
        {
          mergeCarts: 'mergeCarts',
          sourceCartId: currentCartId,
          destinationCartId: newCartId,
        },
      );

      setCart(data.mergeCarts);
      apiState.setCartId(data.mergeCarts.id);
    } else {
      setCart(cart.data.customerCart);
    }
  };

  onMounted(async () => {
    const response = await handleRedirect();

    if (response?.account) {
      const { data, errors } = await mutate<GenerateCustomerTokenQuery>(
        GENERATE_AZURE_TOKEN, {
          code: response.idToken
      });

      if (errors?.length) {
        errors.forEach((error, i) => sendNotification({
          icon: 'error',
          id: Symbol(`azure_token_error-${i}`),
          message: error.message,
          persist: true,
          title: 'Azure authentication error',
          type: 'danger',
        }));
      } else {
        await authCustomer(data.generatedToken.token);
        await mergeCarts();

        try {
          // Load user's cellar after login
          refreshCellar();
        } catch {
          // TODO: Handle errors
          console.error('CPR items fetch failed.');
        }
      }
    }

    await loadCustomer();
  });


  return {
    error,
    create,
    login,
    logout,
  };
};
