import { scrollIntoView } from 'seamless-scroll-polyfill';
import type { Ref } from '@nuxtjs/composition-api'
import type { Plugin } from '@nuxt/types';

interface ScrollIntoViewPlugin {
  $scrollIntoView(
    view: Ref<HTMLElement | null>,
    options?: ScrollIntoViewOptions | null
  ): void;
}

declare module 'vue/types/vue' {
  interface Vue extends ScrollIntoViewPlugin {
  }
}

declare module '@nuxt/types' {
  interface Context extends ScrollIntoViewPlugin {
  }
}

const plugin : Plugin = (_, inject) => {
  inject('scrollIntoView', (view: Ref<HTMLElement | null>, options?: ScrollIntoViewOptions | null): void => {
    if (!view.value) return;
    scrollIntoView(view.value, options)
  });
};

export default plugin;
