





import { useRoute, defineComponent, computed } from '@nuxtjs/composition-api';

export default defineComponent({
  name: 'Visualisation',
  setup() {
    const route = useRoute();

    return {
      route,
      isFullWidth: computed(() => !!route.value?.query?.fullwidth),
    };
  }
});
