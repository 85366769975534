

















import { defineComponent, computed, watch } from '@nuxtjs/composition-api';
import { useUser } from '~/modules/customer/composables/useUser';
import { useWishlist } from '~/modules/wishlist/composables/useWishlist';
import { useWishlistStore } from '~/modules/wishlist/store/wishlistStore';
import {storeToRefs} from 'pinia';

export default defineComponent({
  name: 'Wishlist',
  setup() {
    const { isAuthenticated } = useUser();
    const { loadItemsCount } = useWishlist();
    const wishlistStore = useWishlistStore();
    const { wishlist } = storeToRefs(wishlistStore);
    const qty = computed(() => wishlist.value?.items_count || 0);

    watch(
      () => isAuthenticated.value,
      (newValue) => {
        if (newValue) {
          loadItemsCount();
        }
      },
      { immediate: true }
    );

    return {
      qty,
      isAuthenticated,
    };
  },
});
