import { customerCellarQuery } from '@cellar-services/queries/getCustomerCellar.gql';
import { useApi } from '~/composables';
import { useCellarStore } from '@cellar-services/stores/cellar';
import { storeToRefs } from 'pinia';
import { useContext } from '@nuxtjs/composition-api';
import { useRoute, useRouter } from '@nuxtjs/composition-api';
import { Logger } from '~/helpers/logger';
import { useQueryParams } from '~/bbrTheme/composables/useQueryParams';
import { FamilyType } from '~/bbrTheme/modules/catalog/stores/product';
import { ATTRIBUTE_CODE_MATURITY, SORTED_MATURITY } from '~/bbrTheme/modules/catalog/constants/attributes/maturity';

const ACCOUNT_PAGE = 'customer-my-profile';
const CELLAR_AGGREGATIONS = [ATTRIBUTE_CODE_MATURITY, 'colour', 'family_type', 'stored_status'];
const SORT_ORDER = {
  [ATTRIBUTE_CODE_MATURITY]: SORTED_MATURITY,
  colour: ['Red', 'White', 'Rosé'],
  family_type: [FamilyType.Spirits],
  stored_status: ['Duty paid', 'In bond', 'Lying abroad'],
};

const formatAttributes = (attributes) => {
  const formattedAttributes = {};

  for (const { code, value } of attributes) {
    formattedAttributes[code] = value;
  }

  return formattedAttributes;
};

const formatAggregations = (aggregations) => {
  const formattedAggregations = {};
  let colourTotal = 0;
  let colourWithSpiritTotal = 0;

  for (const { attribute_code, options } of aggregations) {
    if (CELLAR_AGGREGATIONS.includes(attribute_code)) {
      formattedAggregations[attribute_code] = options;
    }
  }

  // TODO: Remove once GraphQL returns stored_status as an aggregation
  if (!('stored_status' in formattedAggregations)) {
    formattedAggregations['stored_status'] = [
      {
        count: 1000,
        label: 'Duty paid',
        value: '114',
        __typename: 'AggregationOption',
        fraction: '0.2000',
        code: 'stored_status',
        fraction_wines: '0.2000',
      },
      {
        count: 1000,
        label: 'In bond',
        value: '115',
        __typename: 'AggregationOption',
        fraction: '0.2000',
        code: 'stored_status',
        fraction_wines: '0.2000',
      },
      {
        count: 3000,
        label: 'Lying abroad',
        value: '116',
        __typename: 'AggregationOption',
        fraction: '0.4000',
        code: 'stored_status',
        fraction_wines: '0.4000',
      },
    ];
  }

  for (const code in formattedAggregations) {
    let total = 0;

    const aggregation = formattedAggregations[code].sort((a, b) => {
      const sortOrder = SORT_ORDER[code];

      return sortOrder.indexOf(a.label) - sortOrder.indexOf(b.label);
    });

    // Total items in all buckets of a single aggregation
    for (const bucket of aggregation) {
      total += bucket.count;
    }

    if (code === 'colour') {
      colourTotal = total;
      colourWithSpiritTotal = total;
    }

    // Fraction of bucket count by total count
    for (const bucket of aggregation) {
      bucket.fraction = Number(bucket.count / total).toFixed(4);
      bucket.code = code;
    }
  }

  // Set colour options as the type options, so that spirits can be added if present
  formattedAggregations['type'] = formattedAggregations['colour'];

  const spiritOption = formattedAggregations['family_type']?.find((option) => option.label === FamilyType.Spirits);

  if (spiritOption) {
    colourWithSpiritTotal = colourTotal + spiritOption.count;

    formattedAggregations['type'].push(spiritOption);
  }

  // Fraction of bucket count by total count
  for (const bucket of formattedAggregations['type']) {
    // Fraction with spirits
    bucket.fraction = Number(bucket.count / colourWithSpiritTotal).toFixed(4);

    // Fraction without spirits
    bucket.fraction_wines = Number(bucket.count / colourTotal).toFixed(4);
  }

  return formattedAggregations;
};

export const useCellar = () => {
  const context = useContext();
  const route = useRoute();
  const router = useRouter();
  const { query } = useApi();
  const { setQueryParams } = useQueryParams();

  const cellar = useCellarStore();
  const { graphQueryParams, isEmpty, pageSize, queryParams, } = storeToRefs(cellar);
  const {
    setItems,
    setIsLoading,
    setIsBlockingLoading,
    setTotalItems,
    setTotalPages,
    setAggregations,
    setStats,
    setAccountId,
    setPageSize,
    setCurrentPage,
    setFilter,
  } = cellar;

  const getCprItems = async (): Promise<void> => {
    const items = {};

    try {
      setIsLoading(true);

      const response = await query<any>(customerCellarQuery, graphQueryParams.value);

      if (response.errors || !response.data) {
        setIsLoading(false);

        Logger.error(response.errors);

        return;
      }

      const data = response.data.customer.cpr_items[0];
      const totalItems = data.total_count;
      const aggregations = formatAggregations(data.aggregations);
      const sortFields = data.sort_fields;
      const pageInfo = data.page_info;

      for (const item of data.items) {
        const attributes = formatAttributes(item.attributes_value);

        items[item.sku] = {
          ...item,
          ...attributes,
        };
      }

      setItems(items);
      setTotalItems(totalItems);
      setTotalPages(pageInfo.total_pages);
      setCurrentPage(context, pageInfo.current_page);
      setPageSize(context, pageInfo.page_size);
      setAccountId(data.account_id);

      /**
       * Set aggregations (used for filters in the overview page) only when the query isn't filtered
       * as a filtered query returns aggregations only for the filtered subset items.
       */
      if (!('filter' in graphQueryParams.value)) {
        setAggregations(aggregations);
      }
    } catch (err) {
      Logger.error(err);
    } finally {
      setIsLoading(false);

      // Redirect to my account page if user has no items
      if (isEmpty.value && route.value.path.startsWith('/cellar')) {
        Logger.info('Redirecting to My account as cellar is empty.');

        if (process.server) {
          context.redirect(context.localePath(ACCOUNT_PAGE));
        } else {
          router.push(context.localeRoute(ACCOUNT_PAGE));
        }
      }
    }
  };

  const getCellarSummary = async (): Promise<void> => {
    const data = await context.$vsf.$cellarServices.api.getCellarSummary();

    setStats(data);
  };

  const refreshCellar = async (): Promise<any> => {
    Logger.info('Refreshing cellar...');

    return Promise.allSettled([getCprItems(), getCellarSummary()]);
  };

  const setPage = async (newPage: number, newSize: number) => {
    // Blocks UI while loading
    setIsBlockingLoading(true);

    // Reset page to 1 if page size was changed
    setCurrentPage(context, newSize !== pageSize.value ? 1 : newPage);
    setPageSize(context, newSize);

    await getCprItems();

    // Set URL params with latest query params
    setQueryParams(queryParams.value);

    setIsBlockingLoading(false);
  };

  // TODO: Handle multiple filters
  const filterCellar = async (filter) => {
    setFilter(filter);

    return await getCprItems();
  }

  return {
    refreshCellar,
    getCprItems,
    getCellarSummary,
    setPage,
    filterCellar,
  };
};
