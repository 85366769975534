import { useContext, ref } from '@nuxtjs/composition-api';
import { Logger } from '~/helpers/logger';

// Delivery keys
export const CELLAR_VALUATION_INFO = 'cellar-valuation-info';
export const CELLAR_MATURITY_STATUS = 'cellar-maturity-status';
export const CELLAR_MARKET_PRICE_VALUATION_INFO = 'cellar-market-price-valuation-info';
export const CELLAR_MARKET_PRICE_GUIDANCE = 'cellar-market-price-guidance';
export const CELLAR_VALUATION_DEFINITIONS = 'cellar-valuation-definitions';
export const CELLAR_CASE_FORMAT_TEXT = 'cellar-case-format-text';

export const useContent = () => {
  const app = useContext();
  const data = ref<any>(null);

  const search = async (id: string, key = 'id'): Promise<any> => {
    try {
      const vse = app.$cookies.get('bbr-vse');
      const [content] = await app.$vsf.$ampl.api.getContent({
        [key]: id,
        preview: vse ? `https://${vse}` : '',
      });
      data.value = content;
    } catch (e) {
      Logger.error('composables/useSearch/amplience-error', `${key}: ${id}`, e);
    }

    return data.value;
  };

  return {
    search,
    data,
  };
}
