
















































import { ImageInterface } from '~/modules/amplience/types';
import { computed, defineComponent, ref, PropType, useContext } from '@nuxtjs/composition-api';
import AccountModal from '~/modules/amplience/components/molecules/header/AccountModal.vue';
import Cart from '~/modules/amplience/components/molecules/header/Cart.vue';
import CountrySelector from '~/modules/amplience/components/molecules/header/CountrySelector.vue';
import Navigation from '~/modules/amplience/components/content-type/Navigation.vue';
import Wishlist from '~/modules/amplience/components/molecules/header/Wishlist.vue';
import WarrantyLogos from '~/modules/amplience/components/molecules/header/WarrantyLogos.vue';
import Logo from '~/modules/amplience/components/molecules/header/Logo.vue';
import Search from '~/modules/amplience/components/molecules/header/Search.vue';
import { NavigationInterface } from '~/modules/amplience/types';
import { SfOverlay } from '@storefront-ui/vue';
import AzureAccountModal from '~/modules/azure-b2c-auth/components/header/AccountModal.vue';
import DefaultCart from '~/bbrTheme/components/header/Cart.vue';
import DefaultWishlist from '~/bbrTheme/components/header/Wishlist.vue';

export default defineComponent({
  /* eslint-disable vue/require-default-prop */
  name: 'AmplienceHeader',
  components: {
    AccountModal,
    AzureAccountModal,
    DefaultCart,
    DefaultWishlist,
    Cart,
    CountrySelector,
    Navigation,
    Wishlist,
    WarrantyLogos,
    SfOverlay,
    Logo,
    Search,
  },
  props: {
    warrantLeft: {
      type: Object as PropType<ImageInterface>,
    },
    warrantRight: {
      type: Object as PropType<ImageInterface>,
    },
    logo: {
      type: Object as PropType<ImageInterface>,
    },
    navigation: {
      type: Object as PropType<NavigationInterface>,
    },
  },
  setup(props) {
    const { $config: { isAzureEnabled } } = useContext();
    const isNavVisible = ref<boolean>(false);

    const handleNav = (): void => {
      isNavVisible.value = !isNavVisible.value;
      document.body.classList.toggle('nav-active', isNavVisible.value);
    };

    const navigationDeliveryId = computed(() => {
      return props.navigation?._meta?.deliveryId || '';
    });

    return {
      isAzureEnabled,
      handleNav,
      isNavVisible,
      navigationDeliveryId,
    };  
  },
});
